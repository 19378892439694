import config from '@/common/config';
import { PromiseResponse } from '@/common/global.interfaces';
import { heapTrack } from '@/common/services/heapTracking';
import type { RootState } from '@/common/store';
import { Http } from '@samknows/utils';
import {
  ActionTree,
  GetterTree,
  Module as VuexModule,
  MutationTree
} from 'vuex';
import { ApiType } from './ApiType.enum';

export interface Token {
  id: number;
  name: string;
  apiName: ApiType;
  isLegacyToken: boolean;
  createdAt: string | null;
  updatedAt: string | null;
}

export interface ApiTokenList {
  apiType: ApiType;
  tokens: Token[];
}

export type ApiTokens = {
  [api in ApiType]?: Token[];
};

export interface ApisViewState {
  apiTokens: ApiTokenList[];
  apiForTokenGeneration: ApiType | null;
  tokenIdToRegenerate: number | null;
  tokenIdToDelete: number | null;
  generateTokenError: string;
  apiError: boolean;
  modalError: boolean;
  modalErrorType: string;
}

export interface PlainApiToken {
  plainToken: string;
  token: Token;
}

export enum TokenApiAction {
  POST = 'POST',
  DELETE = 'DELETE'
}

export interface ApiAccessCheckArgs {
  canAccessApi?: (api: ApiType) => boolean;
}

const state = (): ApisViewState => ({
  apiTokens: [],
  apiForTokenGeneration: null,
  tokenIdToRegenerate: null,
  tokenIdToDelete: null,
  generateTokenError: '',
  apiError: false,
  modalError: false,
  modalErrorType: ''
});

const getters: GetterTree<ApisViewState, RootState> = {
  apiTokens: (state: ApisViewState): ApiTokenList[] => {
    return state.apiTokens;
  },

  isGeneratingToken: (state: ApisViewState): boolean => {
    return state.apiForTokenGeneration !== null;
  },

  isRegeneratingToken: (state: ApisViewState): boolean => {
    return state.tokenIdToRegenerate !== null;
  },

  isDeletingToken: (state): boolean => {
    return state.tokenIdToDelete !== null;
  },

  getTokenName: (state: ApisViewState) => {
    const tokenId = state.tokenIdToDelete ?? state.tokenIdToRegenerate;
    for (const api of state.apiTokens) {
      for (const token of api.tokens) {
        if (token.id === tokenId) {
          return token.name;
        }
      }
    }
    return '';
  },

  getGenerateTokenError: (state: ApisViewState): string => {
    return state.generateTokenError;
  },

  getApiError: (state: ApisViewState): boolean => {
    return state.apiError;
  },

  getModalError: (state: ApisViewState): boolean => {
    return state.modalError;
  },

  getModalErrorType: (state: ApisViewState): string => {
    return state.modalErrorType;
  }
};

const mutations: MutationTree<ApisViewState> = {
  setApiTokens: (state: ApisViewState, tokens: ApiTokenList[]) => {
    state.apiTokens = tokens;
  },

  setApiForTokenGeneration: (state: ApisViewState, apiName: ApiType) => {
    state.apiForTokenGeneration = apiName;
  },

  addApiToken: (
    state: ApisViewState,
    tokenData: { newToken: Token; apiType: ApiType }
  ) => {
    state.apiTokens.forEach((api: ApiTokenList) => {
      if (api.apiType === tokenData.apiType) {
        api.tokens.push(tokenData.newToken);
      }
    });
  },

  setTokenIdToRegenerate: (state: ApisViewState, tokenId: number | null) => {
    state.tokenIdToRegenerate = tokenId;
  },

  replaceApiToken: (
    state: ApisViewState,
    tokenData: { oldTokenId: number; newToken: Token }
  ) => {
    state.apiTokens.forEach((api: ApiTokenList) => {
      const oldTokenIndex = api.tokens.findIndex(
        (token) => token.id === tokenData.oldTokenId
      );
      if (oldTokenIndex >= 0) {
        api.tokens[oldTokenIndex] = tokenData.newToken;
      }
    });
  },

  setTokenIdToDelete: (state, tokenId: number | null) => {
    state.tokenIdToDelete = tokenId;
  },

  removeApiToken: (state: ApisViewState, tokenId: number) => {
    state.apiTokens.forEach((api: ApiTokenList) => {
      api.tokens = api.tokens.filter((token: Token) => token.id !== tokenId);
    });
  },

  setGenerateTokenError: (state: ApisViewState, errorMessage: string) => {
    state.generateTokenError = errorMessage;
  },

  setApiError: (state: ApisViewState, hasError: boolean) => {
    state.apiError = hasError;
  },

  setModalError: (
    state: ApisViewState,
    errorData: { hasError: boolean; modalType: string }
  ) => {
    state.modalError = errorData.hasError;
    state.modalErrorType = errorData.modalType;
  }
};

const actions: ActionTree<ApisViewState, RootState> = {
  async loadAllTokens(
    { commit, rootState },
    { canAccessApi }: ApiAccessCheckArgs = {}
  ): Promise<void> {
    const panelId = rootState.panel.pid;
    try {
      const loadApiTokens: PromiseResponse<ApiTokens> = await Http.request(
        `${config.api.zeus}/panels/${panelId}/api_tokens`
      );

      const apiTokens: ApiTokens = loadApiTokens?.data;

      const apiKeys = Object.keys(apiTokens) as ApiType[];

      const apiTokensList: ApiTokenList[] = [];
      apiKeys.forEach((api) => {
        if (!canAccessApi || canAccessApi(api)) {
          apiTokensList.push({
            apiType: api,
            tokens: apiTokens[api]
          });
        }
      });

      commit('setApiTokens', apiTokensList);
      commit('setApiError', false);

      for (const [apiName, tokens] of Object.entries(apiTokens)) {
        heapTrack(apiName, {
          numberOfTokens: tokens.length
        });
      }
    } catch (error) {
      commit('setApiError', !!error);
      heapTrack('Load all API tokens: Error');
      throw error;
    }
  },

  showGenerateTokenModal({ commit }, apiName: ApiType): void {
    commit('setApiForTokenGeneration', apiName);
  },

  hideGenerateTokenModal({ commit }): void {
    commit('setApiForTokenGeneration', null);
    commit('setGenerateTokenError', '');
    commit('setModalError', { hasError: false, modalType: '' });
  },

  showRegenerateTokenModal({ commit }, tokenId: number): void {
    commit('setTokenIdToRegenerate', tokenId);
  },

  hideRegenerateTokenModal({ commit }): void {
    commit('setTokenIdToRegenerate', null);
    commit('setModalError', { hasError: false, modalType: '' });
  },

  async generateToken(
    { state, rootState, commit, dispatch },
    { name, canAccessApi }: ApiAccessCheckArgs & { name: string }
  ): Promise<string> {
    const panelId = rootState.panel.pid;
    const body = { apiName: state.apiForTokenGeneration, name };

    try {
      const createdTokenResponse = await Http.request<
        PromiseResponse<PlainApiToken>
      >(`${config.api.zeus}/panels/${panelId}/api_token`, {
        method: 'POST',
        body: JSON.stringify(body)
      });

      const createdToken = createdTokenResponse.data.token;
      const createdTokenApiType = createdTokenResponse.data.token.apiName;
      commit('addApiToken', {
        newToken: createdToken,
        apiType: createdTokenApiType
      });

      dispatch('loadAllTokens', { canAccessApi }); // not awaited, should happen in the background
      commit('setGenerateTokenError', '');

      return createdTokenResponse.data.plainToken;
    } catch (error) {
      if (error instanceof Response && error.status === 422) {
        const errorJson = await error.json();
        commit('setGenerateTokenError', errorJson.errors.name[0]);
      } else {
        commit('setModalError', { hasError: !!error, modalType: 'generate' });
        throw error;
      }
    }
  },

  showDeleteTokenModal({ commit }, tokenId: number): void {
    commit('setTokenIdToDelete', tokenId);
  },

  hideDeleteTokenModal({ commit }): void {
    commit('setTokenIdToDelete', null);
    commit('setModalError', { hasError: false, modalType: '' });
  },

  async regenerateToken(
    { rootState, state, commit, dispatch },
    { canAccessApi }: ApiAccessCheckArgs = {}
  ): Promise<string> {
    const panelId = rootState.panel.pid;
    const tokenId = state.tokenIdToRegenerate;
    try {
      const regeneratedTokenResponse = await Http.request<
        PromiseResponse<PlainApiToken>
      >(
        `${config.api.zeus}/panels/${panelId}/api_token/${tokenId}/regenerate`,
        { method: TokenApiAction.POST }
      );

      const regeneratedToken = regeneratedTokenResponse.data.token;
      commit('replaceApiToken', {
        oldTokenId: tokenId,
        newToken: regeneratedToken
      });
      dispatch('loadAllTokens', { canAccessApi }); // not awaited, should happen in the background

      return regeneratedTokenResponse.data.plainToken;
    } catch (error) {
      commit('setModalError', { hasError: !!error, modalType: 'regenerate' });
      throw error;
    }
  },

  async deleteToken(
    { rootState, state, commit, dispatch },
    { canAccessApi }: ApiAccessCheckArgs = {}
  ): Promise<void> {
    const panelId = rootState.panel.pid;
    const tokenId = state.tokenIdToDelete;
    try {
      await Http.request<Promise<void>>(
        `${config.api.zeus}/panels/${panelId}/api_token/${tokenId}`,
        { method: TokenApiAction.DELETE }
      );
      commit('setTokenIdToDelete', null); // hide modal instantly
      commit('removeApiToken', tokenId);
      dispatch('loadAllTokens', { canAccessApi }); // not awaited, should happen in the background
    } catch (error) {
      commit('setModalError', { hasError: !!error, modalType: 'delete' });
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as VuexModule<ApisViewState, RootState>;
